import React, { useState } from 'react';

const DropdownMenu = () => {
    const [isClicked, setIsClicked] = useState('hidden');

    const handleMouseEnter = () => {
        setIsClicked('visible');
        // console.log('isClicked', isClicked);
    };

    const handleMouseLeave = () => {
        setIsClicked('hidden');
        // console.log('isClicked', isClicked);
    };

    return (
        <div className="relative group inline-block text-center">
            <div>
                <button
                    className="max-lg:pl-0 pl-4 hover:bg-gray-700 focus:outline-none"
                    onMouseEnter={handleMouseEnter}
                >
                    {`PLAY GAME >`}
                </button>
            </div>
            <div
                className={`absolute right-0 mt-2 ${isClicked} group-hover:block`}
                onMouseLeave={handleMouseLeave}
            >
                <div className="origin-top-right bg-blackOne text-white">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a href="/create-match">
                            <li className="block px-4 py-2 text-xs text-gray-500 hover:bg-gray-100" role="menuitem">CREATE A MATCH</li>
                        </a>
                        <a href="/join-matches">
                            <li className="block px-4 py-2 text-xs text-gray-500 hover:bg-gray-100" role="menuitem">JOIN A MATCH</li>
                        </a>
                        <a href="/my-matches">
                            <li className="block px-4 py-2 text-xs text-gray-500 hover:bg-gray-100" role="menuitem">MY MATCHES</li>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DropdownMenu;