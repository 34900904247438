import { ethers } from 'ethers';
import contractABI from '../abi/contractABI.json';

const submitPayment = (async (event, amount, provider, account, referral) => {
  event.preventDefault();
  const contractAddress = process.env.REACT_APP_TO_CONTRACT_ADDRESS;
  const getGameID = event.target[0].value;
  console.log('getGameID', getGameID);

  let checkAmount = '';
  // let amountInHex = '';

  if (amount === '0.25') {
    checkAmount = 0.30;
    // amountInHex = '0x429d069189e0000';

  } else if (amount === '0.50') {
    checkAmount = 0.60;
    // amountInHex = '0x1c6bf52634000';

  } else if (amount === '1') {
    checkAmount = 1.2;
    // amountInHex = '0x38d7ea4c68000';
  }

  try {
    const getProvider = new ethers.providers.Web3Provider(provider);
    const signer = getProvider.getSigner();
    const chainId = (await getProvider.getNetwork()).chainId;
    // console.log('chainId', chainId);

    if (chainId === 80084) {
      const contractGap = new ethers.Contract(contractAddress, contractABI, signer);

      // Define the bera_amount in ethers (assuming bera_amount is already in ethers)
      const beraAmountInWei = ethers.utils.parseEther(checkAmount.toString());

      // Send the transaction
      const tx = await contractGap.createMatch(getGameID, referral, {
        value: beraAmountInWei
      });

      // Wait for the transaction to be mined
      const getTransHash = await tx.wait();
      console.log('getTransHash', getTransHash);
      localStorage.removeItem('matches');

      if (getTransHash.status === 1) {
        window.location.reload();
      }

    } else {
      alert('Please change to bartio Network');
    }

  } catch (error) {
    console.log('Error submitting the payment');
  }
});

export default submitPayment;