const readCreateMatches = ((getProviderObj, ethers, contractABI, contractAddress, account) => {

    try {
        const getProvider = new ethers.providers.Web3Provider(getProviderObj);
        const signer = getProvider.getSigner();
        const contractGap = new ethers.Contract(contractAddress, contractABI, signer);
        const getAllMatches = contractGap.userMatchCount(account);
        const arrayMatches = [];

        getAllMatches.then((match) => {
            const getCountMatchesHex = ethers.utils.hexValue(match);
            const getCountMatchesVal = parseInt(Number(getCountMatchesHex));
            // console.log('getCountMatchesVal', getCountMatchesVal);

            for (let j = 0; j < getCountMatchesVal; j++) {
                const getUserMatches = contractGap.userMatches(account, j);
                // console.log('getUserMatches', getUserMatches);

                getUserMatches.then((match) => {
                    let matchObject = {};
                    for (let i = 0; i < getCountMatchesVal; i++) {
                        // console.log('match', match);
                        const getCreatorAddr = match.state.creator;
                        const getJoinerAddr = match.state.adversary;
                        const trimCreatorAddr = match.state.creator.slice(0, 10);
                        const trimAdverAddr = match.state.adversary.slice(0, 10);
                        const getGameIDCreate = match.details.gameID;
                        const getGameIDJoin = match.state.joinerGameID;
                        const getReferral = match.details.referralID;
                        const getBetAmount = match.details.betAmount;
                        const getBetAmountHex = ethers.utils.hexValue(getBetAmount);
                        const getBetAmountNum = parseInt(Number(getBetAmountHex));
                        const trimBalance = (getBetAmountNum / 1000000000000000);
                        const getUserBetAmnt = (trimBalance / 1000).toFixed(2);
                        const getMatchDate = match.details.matchDate;
                        const timestamp = ethers.BigNumber.from(getMatchDate).toNumber();
                        const dateMatch = new Date(timestamp * 1000);
                        const getClaimedChat = match.details.claimed;
                        const convMatchIdHex = match.state.matchStarted;
                        const getMatchId = j;
                        const checkWinnerCreator = match.state.creatorExtraInfo;
                        const checkWinnerAdversary = match.state.adversaryExtraInfo;
                        matchObject.creatorAddress = getCreatorAddr;
                        matchObject.JoinerAddress = getJoinerAddr;
                        matchObject.getCreatorAddr = trimCreatorAddr;
                        matchObject.getJoinerAddr = trimAdverAddr;
                        matchObject.getGameIDCreate = getGameIDCreate;
                        matchObject.getGameIDJoin = getGameIDJoin;
                        matchObject.getReferral = getReferral;
                        matchObject.getUserBetAmnt = getUserBetAmnt;
                        matchObject.dateMatch = dateMatch;
                        matchObject.getClaimedChat = getClaimedChat;
                        matchObject.getMatchStatus = convMatchIdHex;
                        matchObject.getMatchId = getMatchId;
                        console.log('checkWinnerCreator', checkWinnerCreator);
                        if (checkWinnerCreator === 'win') {
                            matchObject.getWinner = 'Creator Wins';
                        }
                        if (checkWinnerAdversary === 'win') {
                            matchObject.getWinner = 'Adversary Wins';
                        }
                        // console.log('arrayMatches', arrayMatches);
                    }
                    arrayMatches.push(matchObject);
                    localStorage.setItem('mymatches', JSON.stringify(arrayMatches));
                })
            }
        });

    } catch (error) {
        console.log('Error loading Matches');
    }
});

export default readCreateMatches;