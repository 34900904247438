const readCreateMatches = ((getProviderObj, ethers, contractABI, contractAddress, account) => {

    try {
        const getProvider = new ethers.providers.Web3Provider(getProviderObj);
        const signer = getProvider.getSigner();
        const contractGap = new ethers.Contract(contractAddress, contractABI, signer);
        const getAllMatches = contractGap.getAllMatches();
        const arrayMatches = [];

        getAllMatches.then((match) => {

            for (let i = 0; i < match.length; i++) {
                let matchObject = {};
                // console.log('match', match);
                const getGameID = match[i].details.gameID;
                const getReferral = match[i].details.referralID;
                const getBetAmount = match[i].details.betAmount;
                const getBetAmountHex = ethers.utils.hexValue(getBetAmount);
                const getBetAmountNum = parseInt(Number(getBetAmountHex));
                const trimBalance = (getBetAmountNum / 1000000000000000);
                const showBalance = (trimBalance / 1000).toFixed(2);
                const getMatchID = match[i].details.matchDate;
                const timestamp = ethers.BigNumber.from(getMatchID).toNumber();
                const dateMatch = new Date(timestamp * 1000);
                const getClaimedChat = match[i].details.claimed;
                const getCreatorAddr = match[i].state.creator;
                const trimCreatorAddr = match[i].state.creator.slice(0, 10);
                const getMatchId = match[i].matchID;
                const convMatchIdHex = ethers.utils.hexValue(getMatchId);
                const getHexInValue = parseInt(Number(convMatchIdHex));
                const getAdversary = match[i].state.adversary;
                const getStatusChat = match[i].state.chatRoom;
                matchObject.creatorAddress = getCreatorAddr;
                matchObject.getCreatorAddr = trimCreatorAddr;
                matchObject.getGameID = getGameID;
                matchObject.getReferral = getReferral;
                matchObject.showBalance = showBalance;
                matchObject.dateMatch = dateMatch;
                matchObject.getClaimedChat = getClaimedChat;
                matchObject.getHexInValue = getHexInValue;
                matchObject.getAdversary = getAdversary;
                matchObject.getStatusChat = getStatusChat;
                arrayMatches.push(matchObject);
            }
            localStorage.setItem('matches', JSON.stringify(arrayMatches));
        });

    } catch (error) {
        console.log('Error loading Matches');
    }
});

export default readCreateMatches;