import React, { useEffect, useState } from 'react';
import twitterIcon from '../assets/face-icon.png';
import faceIcon from '../assets/face-icon.png';
import youtubeIcon from '../assets/youtube-icon.png';
import HeaderComp from './HeaderComp';
import { useSDK } from "@metamask/sdk-react";
import { ethers } from 'ethers';
import contractABI from './abi/contractABI.json';
import readCreateMatches from './functions/joinMatch';
import submitResult from './functions/payJoinMatch';
import './joinMatches.css';

// const ClientCreateMatch = dynamic(() => import('@/components/CreateMatchEthers'), {
//     ssr: false
// });

const JoinMatches = (() => {
    const { sdk, chainId, account } = useSDK();
    const getProviderObj = sdk?.getProvider();
    const contractAddress = process.env.REACT_APP_TO_CONTRACT_ADDRESS;
    const adversarStdArr = "0x0000000000000000000000000000000000000000";

    if (sdk !== 'undefined' && sdk !== undefined) {
        const accounts = sdk?.connect();
        accounts.then((currAccount) => {
            const getAccCurr = currAccount[0];
            readCreateMatches(getProviderObj, ethers, contractABI, contractAddress, getAccCurr);
        });
    }

    let getMatchesArray = JSON.parse(localStorage.getItem('matches'));

    if (getMatchesArray !== 'null' && getMatchesArray !== null) {
        getMatchesArray = JSON.parse(localStorage.getItem('matches'));

    } else {
        getMatchesArray = [];
    }

    const handleSubmitMatch = ((event) => {
        event.preventDefault();
        console.log('event', event);
        const getGameIdAdversary = event.target[0].value;
        const getValueJoinMatch = event.target[1].value;
        console.log('getValueJoinMatch', getValueJoinMatch);
        const getMatchId = event.target.title;
        console.log('getMatchId', getMatchId);
        const getCreatorAddress = event.target.id;
        console.log('getCreatorAddress', getCreatorAddress);

        // accounts.then((currAccount) => {
        // const getAccCurr = currAccount[0];
        // submitResult(event, getValueJoinMatch, getProviderObj, getMatchId, getCreatorAddress, getGameIdAdversary);
        // });
    });

    // console.log('getMatchesArray', getMatchesArray);

    return (
        <div className="flex flex-col">
            <HeaderComp />
            <section id="create-bg" className="flex flex-col min-w-full">
                <article className="bg-blackOne mx-20 mt-20 p-6 rounded-3xl overflow-auto">
                    <div className="flex justify-end text-white">
                        <span>Sort:</span>
                    </div>
                    <table className="w-full border-separate border-spacing-y-3">
                        <thead>
                            <tr className="text-white">
                                <th></th>
                                <th className="text-left">Creator Address</th>
                                <th className="text-left">Game ID</th>
                                <th className="text-left">Match Date/Time</th>
                                <th className="text-left">Bet Amount</th>
                                <th className="text-left">Chat Room</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getMatchesArray.length > 0 &&
                                getMatchesArray.map((match, index) =>
                                    <tr className="bg-purpTable text-white text-xs h-16" key={index}>
                                        <td className="rounded-l-lg">
                                            {match.getAdversary === adversarStdArr &&
                                                <form
                                                    className="flex"
                                                    onSubmit={handleSubmitMatch}
                                                    title={match.getGameID}
                                                    id={match.creatorAddress}
                                                >
                                                    <div className="ml-2">
                                                        <input
                                                            type="text"
                                                            id="text-join"
                                                            className="bg-gray-50 w-64 p-2.5 text-sm border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                            placeholder="Enter Game Tag Name"
                                                            required
                                                        />
                                                    </div>
                                                    <button
                                                        type="submit"
                                                        value={match.showBalance}
                                                        className="flex items-center rounded-r-md text-white text-sm px-8 text-center bg-redOne hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                        Join
                                                    </button>
                                                </form>
                                            }
                                            {match.getAdversary !== adversarStdArr &&
                                                <span
                                                    className="flex justify-center rounded-md text-white text-sm px-2 mx-1 bg-redOne hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                                >
                                                    Joined
                                                </span>
                                            }
                                        </td>
                                        <td className="text-left">{match.getCreatorAddr}</td>
                                        <td className="text-left">{match.getGameID}</td>
                                        <td className="text-left">{match.dateMatch.slice(0, match.dateMatch.length - 5)}</td>
                                        <td className="text-left">{match.showBalance}</td>
                                        {match.getAdversary === adversarStdArr &&
                                            <td className="text-left">Chat Disabled</td>
                                        }
                                        {match.getAdversary !== adversarStdArr &&
                                            <td className="text-left">Chat Available</td>
                                        }
                                    </tr>
                                )
                            }
                            {getMatchesArray.length <= 0 &&
                                <tr className="bg-purpTable text-white h-16">
                                    <td className="rounded-l-lg">
                                        <form className="flex ">

                                        </form>
                                    </td>
                                    <td className="text-left"></td>
                                    <td className="text-left"></td>
                                    <td className="text-left"></td>
                                    <td className="text-left"></td>
                                    <td className="text-left"></td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </article>
                <footer id="footer-container" className="flex flex-col text-sm mx-20 mt-40 mb-40">
                    <div id="footer-top" className="flex justify-between mb-10">
                        <div id="footer-menu" className="text-white opacity-70">
                            <a href="/" className="mr-6">Home</a>
                            <a href="/" className="mr-6">Documentation</a>
                            <a href="/" className="mr-6">Features</a>
                            <a href="/" className="mr-6">Play Games</a>
                        </div>
                        <div className="flex">
                            <img
                                src={twitterIcon}
                                alt="Follow us on Twitter"
                                width={24}
                                height={24}
                                className="mr-10"
                            />
                            <img
                                src={faceIcon}
                                alt="Follow us on Twitter"
                                width={24}
                                height={24}
                                className="mr-10"
                            />
                            <img
                                src={youtubeIcon}
                                alt="Follow us on Twitter"
                                width={24}
                                height={24}
                            />
                        </div>
                    </div>
                    <hr id="hr-footer" className="border-1 opacity-20" />
                    <div id="footer-bottom" className="flex justify-between mt-10">
                        <span className="text-white opacity-60">
                            © 2024 GAP. All rights reserved.
                        </span>
                        <div className="text-white opacity-70">
                            <a href="/" className="mr-10">Terms of Service</a>
                            <a href="/">Privacy Policy</a>
                        </div>
                    </div>
                </footer>
            </section>
        </div>
    )
})

export default JoinMatches;