import HeaderComp from './HeaderComp';
import firstAbout from '../assets/img1-about.png';
import secondAbout from '../assets/img2-about.svg';
import thirdAbout from '../assets/img3-about.svg';
import twitterIcon from '../assets/face-icon.png';
import faceIcon from '../assets/face-icon.png';
import youtubeIcon from '../assets/youtube-icon.png';
// import Link from 'next/link';

const Home = (() => {

    return (
        <main className="flex flex-col bg-slate-500">
            <HeaderComp />
            <section id="about-bg" className="flex flex-col min-w-full">
                <article id="about-container" className="flex flex-col mx-20 items-center font-sans mt-48">
                    <h2 className="text-white max-md:text-2xl text-5xl">About us</h2>
                    <p className="text-white max-lg:text-sm max-md:text-xs text-lg text-center w-4/5 mt-8">
                        GAP is a game changing dApp set to disrupt the WEB3 gaming landscape.
                        Allowing players to play and wager on games they already know and love.
                        This is the missing link in connecting to the worlds of gaming.
                        We are bringing a fresh approach to how players experience gaming with our PLAY-AND-EARN platform.
                        GAP completely lowers the barrier of entry for players to earn crypto playing AAA title games.
                        All you need is a Steam account and a Meta Mask wallet. It doesn't get simpler than that.
                    </p>
                    <button type="button" className="text-white max-md:text-xs bg-redOne p-3 max-md:w-3/5 w-2/5 rounded-lg mt-8">
                        Start Playing and Earning Now
                    </button>
                </article>
                <article id="fifa-section" className="flex justify-between font-sans mx-20 mt-48">
                    <img
                        src={firstAbout}
                        alt="FIFA IMG"
                        className="max-lg:w-96"
                    />
                    <div className="flex flex-col justify-center items-center w-2/5 max-md:w-3/5">
                        <h2 className="text-white max-lg:text-4xl max-md:text-2xl text-5xl max-lg:mb-4 mb-12">FC 24</h2>
                        <p className="text-white max-lg:text-sm max-md:text-xs text-lg max-lg:mb-4 mb-12">
                            EA SPORTS FC™ 24 kicks off a new era of The World's Game.
                            Meet the new brand and the three technologies
                            powering the true-to-football experience.
                        </p>
                        <button type="button" className="text-white bg-redOne p-6 w-full rounded-lg">
                            PLAY GAME
                        </button>
                    </div>
                </article>
                <article id="street-section" className="flex justify-between font-sans mx-20 mt-48">
                    <div className="flex flex-col justify-center items-center w-2/5 max-md:w-3/5">
                        <h2 className="text-white max-lg:text-4xl max-md:text-xl text-5xl max-lg:mb-4 mb-12">Street Fighter</h2>
                        <p className="text-white max-lg:text-sm max-md:text-xs text-lg max-lg:mb-4 mb-12">
                            Street Fighter is a Japanese media franchise
                            centered on a series of fighting games
                            developed and published by Capcom
                        </p>
                        <button type="button" className="text-white bg-redOne p-6 w-full rounded-lg">
                            PLAY GAME
                        </button>
                    </div>
                    <img
                        src={secondAbout}
                        alt="STREET IMG"
                        className="max-lg:w-96"
                    />
                </article>
                <article id="madden-section" className="flex justify-between font-sans mx-20 mt-48">
                    <img
                        src={thirdAbout}
                        alt="MADDEN IMG"
                        className="max-lg:w-96"
                    />
                    <div className="flex flex-col justify-center items-center w-2/5 max-md:w-3/5">
                        <h2 className="text-white max-lg:text-4xl max-md:text-2xl text-5xl max-lg:mb-4 mb-12">Madden 24</h2>
                        <p className="text-white max-lg:text-sm max-md:text-xs text-lg max-lg:mb-4 mb-12">
                            Madden NFL 24 is an American football video game developed
                            by EA Tiburon and published by EA Sports.
                        </p>
                        <button type="button" className="text-white bg-redOne p-6 w-full rounded-lg">
                            PLAY GAME
                        </button>
                    </div>
                </article>
                <footer id="footer-container" className="flex flex-col text-sm mx-20 mt-40 mb-40">
                    <div id="footer-top" className="flex justify-between mb-10">
                        <div id="footer-menu" className="text-white opacity-70">
                            <a href="/" className="mr-6">Home</a>
                            <a href="/" className="mr-6">Documentation</a>
                            <a href="/" className="mr-6">Features</a>
                            <a href="/" className="mr-6">Play Games</a>
                        </div>
                        <div className="flex">
                            <img
                                src={twitterIcon}
                                alt="Follow us on Twitter"
                                width={24}
                                height={24}
                                className="mr-10"
                            />
                            <img
                                src={faceIcon}
                                alt="Follow us on Twitter"
                                width={24}
                                height={24}
                                className="mr-10"
                            />
                            <img
                                src={youtubeIcon}
                                alt="Follow us on Twitter"
                                width={24}
                                height={24}
                            />
                        </div>
                    </div>
                    <hr id="hr-footer" className="border-1 opacity-20" />
                    <div id="footer-bottom" className="flex justify-between mt-10">
                        <span className="text-white opacity-60">
                            © 2024 GAP. All rights reserved.
                        </span>
                        <div className="text-white opacity-70">
                            <a href="/" className="mr-10">Terms of Service</a>
                            <a href="/">Privacy Policy</a>
                        </div>
                    </div>
                </footer>
            </section>
        </main>
    );
});

export default Home;