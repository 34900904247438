import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import CreateMatch from './components/CreateMatch';
import JoinMatches from './components/JoinMatches';
import MyMatches from './components/MyMatches';
import Docs from './components/Docs';
import './App.css';

const App = (() => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/create-match" element={<CreateMatch />} />
        <Route path="/join-matches" element={<JoinMatches />} />
        <Route path="/my-matches" element={<MyMatches />} />
        <Route path="/docs" element={<Docs />} />
      </Routes>
    </Router>
    
  );
});

export default App;
