import React, { useState } from 'react';
import { useSDK } from "@metamask/sdk-react";
import { ethers } from 'ethers';

const MetamaskComp = (() => {
    const { sdk } = useSDK();
    const [currBalance, setCurrBalance] = useState('0.00');
    // const { ethereum } = window;
    // const CheckMetaExt = ethereum.isMetaMask;
    // const checkConnect = ethereum.isConnected();
    const getProviderObj = sdk?.getProvider();
    // const bartioChainId = '0x138d4';

    // if (CheckMetaExt) {

    //     if (checkConnect) {

    if (getProviderObj !== 'undefined' && getProviderObj !== undefined) {
        getProviderObj.on('chainChanged', () => {

            localStorage.removeItem('mymatches');
            localStorage.removeItem('matches');
            setTimeout(window.location.reload(), 3000);
            // window.location.reload();
        });

        getProviderObj.on('accountsChanged', () => {
            window.location.reload();
        });
    }
    //     }
    // }

    const initMetaMask = async () => {

        //if (CheckMetaExt === true) {

        //if (checkConnect === true) {
        try {

            //if (chainId === bartioChainId) {
            const accounts = await sdk?.connect();
            const getAccCurr = accounts[0];
            const provider = new ethers.providers.Web3Provider(getProviderObj);
            const chainId = (await provider.getNetwork()).chainId;

            if (chainId === 80084) {
                const mainAccBalance = await provider.getBalance(getAccCurr);
                const selectvHexValue = ethers.utils.hexValue(mainAccBalance);
                const convertHexToDec = parseInt(Number(selectvHexValue));
                const trimBalance = (convertHexToDec / 1000000000000000);
                const showBalance = (trimBalance / 1000).toFixed(2);
                setCurrBalance(showBalance);
                // console.log('setCurrAccount', currAccount);

                // } else if (chainId !== bartioChainId) {
                //     alert('Your not in Berachain Bartio network');
                // }

            } else {
                alert('Please change to bartio Network');
            }

        } catch (error) {
            console.error('Error initializing MetaMask:', error);
        }
        // } else {
        //     console.warn("failed to connect..");
        // }
        // } else {
        //     console.error('MetaMask is not installed');
        // }
    };

    return <button type="button" onClick={initMetaMask}>METAMASK {currBalance}</button>
});

export default MetamaskComp;