import { ethers } from 'ethers';
import contractABI from '../abi/contractABI.json';

const submitResult = (async (event, provider, getMatchId, getCreatorAddress, getAccCurr, getTextResult) => {
    event.preventDefault();
    const contractAddress = process.env.REACT_APP_TO_CONTRACT_ADDRESS;
    const stringLowCaseRes = getTextResult.toLowerCase();
    // ddress creator, uint256 matchID, address currentUser, string memory someString
    // const getGameID = event.target[0].value;
    // console.log('getGameID', getGameID);
    // console.log('account', account);
    // console.log('getCreatorAddress', getCreatorAddress);
    // console.log('getMatchId', getMatchId);
    // console.log('getAccCurr', getAccCurr);
    // console.log('getTextResult', getTextResult);

    let checkResultInput = '';

    if (stringLowCaseRes === 'win') {
        checkResultInput = 'win';

    } else if (stringLowCaseRes === 'lose') {
        checkResultInput = 'lose';
    }

    try {
        if (checkResultInput !== '') {
            const getProvider = new ethers.providers.Web3Provider(provider);
            const signer = getProvider.getSigner();
            const chainId = (await getProvider.getNetwork()).chainId;

            if (chainId === 80084) {
                const contractGap = new ethers.Contract(contractAddress, contractABI, signer);

                // Send the transaction
                const tx = await contractGap.triggerUser(getCreatorAddress, getMatchId, getAccCurr, checkResultInput);

                // Wait for the transaction to be mined
                const getTransHash = await tx.wait();
                console.log('getTransHash', getTransHash);
                localStorage.removeItem('mymatches');

                if (getTransHash.status === 1) {
                    window.location.reload();
                }
            } else {
                alert('Check input result, should be win or lose');
            }
        } else {
            alert('Please change to bartio Network');
        }

    } catch (error) {
        console.log('Error submitting the payment');
    }
});

export default submitResult;